import { useState, useEffect, useRef, useCallback } from 'react'
import constants from '../../utilities/constants'
import OurLearnersWorkAt from './OurLearnersWorkAt'
import UsersModal from './UsersModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Mixpanel } from '../../config/Mixpanel'
import { useDispatch, useSelector } from 'react-redux'
import { decrementTime, selectLandingPageDetails, selectShowCategoryModal, selectShowCourseModal, selectShowPlacementModal, selectShowRelatedSubjectsModal, selectTimerSeconds, selectUserDetails, setUserDetails, setUserSkillDetails } from '../../redux/otherDetailsSlice'
import LinkdInUsers from './LinkdInUsers'
import BottomStickyBar from './BottomStickyBar'
import Pricingcard from './Pricingcard'
import Timer from './Timer'
import CertificateRecognition from './CertificateRecognition'
import CertificateHelp from './CertificateHelp'
import AwardWinning from './AwardWinning'
import BottomPricingcard from './BottomPricingcard'
import { getUserFullDetails, selectMCQDetails, selectTypeformData } from '../../redux/mcqSlice'
import { getUserDetails, setAuthToken } from '../../redux/authenticationSlice'
import { getProductDetails, selectPriceChart } from '../../redux/priceChartSlice'
import CertifiedFAQSection from './CertifiedFAQSection'
import { toast } from 'react-toastify'
import { getRelatedSkills } from '../../redux/multiSkillSelectionSlice'
import { fetchCourseSyllabus, selectshowCertificateImageModal } from '../../redux/postPaymentSlice'
import { customCertifiedBundle, selectSelectedItems } from '../../redux/searchSlice'
import { fetchRelatedJobs } from '../../redux/influencersDataSlice'
import CertificateImageModal from '../PostCertifcatePurchase/CertificateImageModal'
import NewCourseDetailsModal from './NewCourseDetailsModal'
import CategoryModal from '../../components/CategoryModal'
import FourWeekPlacementModal from './FourWeekPlacementModal'
import RelatedSubjectsModal from './RelatedSubjectsModal'
import { selectAddOns, selectPrice, setAnalysisPageUtmSource, setPrice } from '../../redux/addOnSlice'
import { useAddonContext } from '../../context/AddonFeatureContext'


const costlyCertificates = ["azure", "growth marketing", "aws"]
const cheapCertificates = ["scrum master", "sql", "advanced excel", "artificial intelligence", "data analytics", "mongo db", "certified professional data engineer", "certified aws solutions architect", "certified project management professional", "certified scrummaster", "microsoft azure cloud professional", "business analyst", "expert certification in salesforce deployment", "certified information systems security professional", "certified supply chain manager", "fundamentals of docker", "fundamentals of kubernetes", "python", "mobile developer", "seo", "jira", "digital marketing", "machine learning", "data science", "big data analytics", "cybersecurity", "ethical hacking", "blockchain development", "web development", "software development", "full stack development", "python programming", "sql & database", "aws cloud", "devops essentials", "business intelligence", "data visualization with power bi", "data visualization with tableau", "agile coaching", "lean management basics", "change management", "social media marketing", "content strategy", "motion design", "content creation", "creator economy mastery", "business communication skills", "leadership skills", "leadership skill", "operations management", "hr management", "customer success", "gaming development", "event management", "business communication"]
export const nineNintyNine = ["business management", "hospitality management", "business project management"]
export const fourNinetyNine = ["strategic management", "financial analyst", "excel", "tally", "brand management", "supply chain management", "risk management", "data visualization", "chatgpt prompts"]
export const twoFortyNine = ["customer relationship management (crm)", "financial modeling", "business english", "docker & kubernetes", "python fundamentals", "ai & machine learning", "internet of things (iot)", "ai video editing", "graphic design with canva", "ai content creation", "game development"]

const settings = {
    dots: false,
    slidesToShow: 4,
    rows: 2,
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: 'linear',
    cssEase: "linear",
    infinite: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                infinite: true,
                dots: false,
                arrows: false,
                swipeToSlide: false,
            }
        },
    ]
};

const AnalysisPage = () => {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const utm_source = params.get('utm_source')

    const userDetails = useSelector(selectUserDetails)
    const landingPage = useSelector(selectLandingPageDetails)
    const typeformData = useSelector(selectTypeformData)
    const quizDetails = useSelector(selectMCQDetails)
    const priceChart = useSelector(selectPriceChart)
    const selectedSkills = useSelector(selectSelectedItems)
    const showCertificateImageModal = useSelector(selectshowCertificateImageModal)
    const showCourseModal = useSelector(selectShowCourseModal)
    const showCategoryModal = useSelector(selectShowCategoryModal)
    const showRelatedSubjectsModal = useSelector(selectShowRelatedSubjectsModal)
    const timerSeconds = useSelector(selectTimerSeconds)
    const price = useSelector(selectPrice)
    const addOns = useSelector(selectAddOns)
    const showPlacementModal = useSelector(selectShowPlacementModal)
    
    const userName = userDetails?.user_name
    const firstName = userName?.split(" ")?.[0]
    const skill_name = landingPage?.skill_name
    const email = userDetails?.email
    const phoneNo = userDetails?.phone_no
    const score = quizDetails?.quiz_score
    const minutes = Math.floor(timerSeconds/60)
    const seconds = timerSeconds%60
    const { threeCertificates, fiveCertificates, sevenCertificates, proMembershipSelected, personalisedSelected, fourWeekPlacementFeature } = addOns

    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ isAtEnd, setIsAtEnd ] = useState(false)

    const navigate = useNavigate()
    const sentinelRef = useRef(null);
    const dispatch = useDispatch()
    const scrollRef = useRef(null)
    const { token } = useParams()

    const skillIsBusinessManagement = nineNintyNine?.includes(skill_name?.toLowerCase())
    const { skillIsNotSeo } = useAddonContext()

    useEffect(()=>{
        const fetchData = async () => {
            try {
                dispatch(setAuthToken(token))
                const response = await dispatch(getUserDetails(token)).unwrap()
                const { certified_user_skill_id, name, email, phone_number } = response
                dispatch(setUserDetails({ name, email, phone_number }))
                const userDetailsResponse = await dispatch(getUserFullDetails({ certified_user_skill_id })).unwrap()
                const { subject_name } = userDetailsResponse
                dispatch(setUserSkillDetails(subject_name))
                Mixpanel.identify(email)
                Mixpanel.track(constants.Mixpanel.SAVED_ANALYSIS_PAGE ,{
                    distinct_id: email
                })
                Mixpanel.people.set({
                    "name": name,
                    "phoneNo": phone_number,
                    "skillName": subject_name
                })
            } catch (error) {
                console.log(error.message)
            }
        }
        if (token){
            fetchData()
        }else{
            Mixpanel.identify(email)
            Mixpanel.track(constants.Mixpanel.ANALYSIS_PAGE, {
                distinct_id: email
            })
            Mixpanel.people.set({
                "name": userName,
                "phoneNo": phoneNo,
                "skillName": skill_name
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        dispatch(getProductDetails({ certified_product_id: 2 }))
        dispatch(getProductDetails({ certified_product_id: 3 }))
        dispatch(getProductDetails({ certified_product_id: 4 }))
        dispatch(getProductDetails({ certified_product_id: 9 }))
        dispatch(getProductDetails({ certified_product_id: 10 }))
        dispatch(getProductDetails({ certified_product_id: 11 }))
        dispatch(getProductDetails({ certified_product_id: 21 }))
        dispatch(getProductDetails({ certified_product_id: 31 }))
        dispatch(getProductDetails({ certified_product_id: 32 }))
        dispatch(getProductDetails({ certified_product_id: 33 }))
        dispatch(getProductDetails({ certified_product_id: 34 }))
        dispatch(getProductDetails({ certified_product_id: 37 }))
        dispatch(getProductDetails({ certified_product_id: 38 }))
        dispatch(getProductDetails({ certified_product_id: 39 }))
        dispatch(getProductDetails({ certified_product_id: 40 }))     
    }, [dispatch])

    useEffect(() => {
        const fetchdata = async () => {
            try {
                let response
                if (skillIsBusinessManagement){
                    response = await dispatch(getProductDetails({ certified_product_id: 35 })).unwrap()
                }else if (fourNinetyNine.includes(skill_name?.toLowerCase())){
                    response = await dispatch(getProductDetails({ certified_product_id: 36 })).unwrap()
                }else if (landingPage?.utm_source === 'thedataschoool' && skill_name === 'SQL'){
                    response = await dispatch(getProductDetails({ certified_product_id: 1 })).unwrap()
                }else if (costlyCertificates?.includes(skill_name?.toLowerCase())){
                    response = await dispatch(getProductDetails({ certified_product_id: 16 })).unwrap()
                }else if (cheapCertificates?.includes(skill_name?.toLowerCase())){ 
                    response = await dispatch(getProductDetails({ certified_product_id: 17 })).unwrap()
                }else if (twoFortyNine?.includes(skill_name?.toLowerCase())){
                    response = await dispatch(getProductDetails({ certified_product_id: 61 })).unwrap()
                }else {
                    response = await dispatch(getProductDetails({ certified_product_id: 1 })).unwrap()
                }
                dispatch(setPrice(response?.data))
            } catch (error) {
                console.log(error.message)
            }
        }
        fetchdata()
    }, [dispatch, skill_name, skillIsBusinessManagement, landingPage?.utm_source, score])

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(fetchRelatedJobs({ subject: skill_name }))
                dispatch(getRelatedSkills({ subject_name: skill_name, n: 25 }))
                dispatch(fetchCourseSyllabus({ type: 'default', subject: skill_name, goal: `Learn ${skill_name}` }))
            } catch (error) {
                toast.error('Something Went Wrong!')
            }
        }
        if (skill_name){
           fetchData()
        }
    }, [dispatch, skill_name])

    useEffect(() => {
        if (utm_source){
            dispatch(setAnalysisPageUtmSource(utm_source))
        }
    }, [dispatch, utm_source])

    const handleTotalPrice = useCallback(() => {
        let sum1 = threeCertificates ?  (skillIsBusinessManagement ? priceChart?.[37]?.original_price : priceChart?.[2]?.original_price) : 0
        let sum2 = fiveCertificates ? (skillIsBusinessManagement ? priceChart?.[38]?.original_price : priceChart?.[10]?.original_price) : 0
        let sum3 = sevenCertificates ? (skillIsBusinessManagement ? priceChart?.[39]?.original_price : priceChart?.[11]?.original_price) : 0
        let sum4 = personalisedSelected ? priceChart?.[4]?.original_price : 0
        let sum5 = proMembershipSelected ? priceChart?.[3]?.original_price : 0
        let sum6 = fourWeekPlacementFeature ? priceChart?.[34]?.original_price : 0
        let sum10 = selectedSkills?.length > 0 ? selectedSkills?.length * (199) : 0
        
        return sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum10
    }, [fiveCertificates, fourWeekPlacementFeature, personalisedSelected, priceChart, proMembershipSelected, selectedSkills?.length, sevenCertificates, skillIsBusinessManagement, threeCertificates])

    const handleDiscountedPrice = useCallback(() => {
        let sum1 = threeCertificates ?  (skillIsBusinessManagement ? priceChart?.[37]?.price : priceChart?.[2]?.price) : 0
        let sum2 = fiveCertificates ? (skillIsBusinessManagement ? priceChart?.[38]?.price : priceChart?.[10]?.price) : 0
        let sum3 = sevenCertificates ? (skillIsBusinessManagement ? priceChart?.[39]?.price : priceChart?.[11]?.price) : 0
        let sum4 = personalisedSelected ? priceChart?.[4]?.price : 0
        let sum5 = proMembershipSelected ? priceChart?.[3]?.price : 0
        let sum6 = fourWeekPlacementFeature ? priceChart?.[34]?.price : 0
        let sum10 = selectedSkills?.length > 0 ? (Math.round((selectedSkills?.length * (199) * (1 - (Math.min(selectedSkills?.length * 5 /100, 0.5)))))) : 0

        return sum1 + sum2 + sum3 + sum4 + sum5 + sum6 + sum10
    }, [fiveCertificates, fourWeekPlacementFeature, personalisedSelected, priceChart, proMembershipSelected, selectedSkills?.length, sevenCertificates, skillIsBusinessManagement, threeCertificates])

    useEffect(() => {
        if (typeformData?.is_paid){
            return navigate('/analysis/leaderboard/v2')
        }
    }, [navigate, typeformData?.is_paid])

    useEffect(() => {
        let timer;
        if (timerSeconds > 0) {
            timer = setInterval(() => {
                dispatch(decrementTime());
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [dispatch, timerSeconds])

    useEffect(() => {
        const sentinel = sentinelRef.current;
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsAtEnd(true);
            } else {
                setIsAtEnd(false);
            }
        })
        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }
        return () => {
            if (sentinel) {
                observer.unobserve(sentinel);
            }
        }
    }, [sentinelRef])

    useEffect(() => {
        if (!skillIsNotSeo && selectedSkills?.length > 0){
            const skillArray = selectedSkills.map((item) => {
                return { course_name: item }
            })
            dispatch(customCertifiedBundle({ course_list: skillArray, bundle_type: 'custom_discounted_certified_bundle', certified_user_skill_id: typeformData?.id }))
        }
    }, [dispatch, selectedSkills, skillIsNotSeo, typeformData?.id])

    const handleNavigate = () => navigate('/analysis/certificate-bundle')

    return (
        <>
            <div className='flex-1 max-w-3xl mx-auto text-white text-center w-full relative'>
                <BottomStickyBar
                    isAtEnd={isAtEnd}
                    minutes={minutes}
                    seconds={seconds}
                    price={price}
                    featurePrice={handleDiscountedPrice}
                    normalFeaturePrice={handleTotalPrice}
                    handleNavigate={handleNavigate}
                />
                <div className='h-[1px] w-[1px] absolute -top-[150px]' ref={scrollRef}></div>
                    {score >= 40 ? <h2 className='text-[28px] lg:text-[44px] lg:leading-[50px] font-medium leading-[120%] mt-5 md:mt-3'>Congrats {firstName}! <span className='font-semibold text-span_color'>You’ve Passed!</span> 🚀</h2> : <h2 className='text-[28px] lg:text-[44px] lg:leading-[50px] font-medium leading-[120%] mt-5 md:mt-3'>Awesome {firstName}! <span className='font-semibold text-span_color'>Test Completed!</span> 🚀</h2>}
                <div className='max-w-xl mx-auto'>
                    <Pricingcard
                        score={score}
                        skill_name={skill_name}
                        price={price}
                        featurePrice={handleDiscountedPrice}
                        normalFeaturePrice={handleTotalPrice}
                        handleNavigate={handleNavigate}
                    />
                    <Timer
                        minutes={minutes}
                        seconds={seconds}
                    />
                    <div className='w-full mt-6 md:mt-10'>
                        <h2 className='text-2xl md:text-[32px] font-semibold text-left mb-3 leading-[30px] md:leading-[34px]'>Certificate Recognised By 1100+ Companies</h2>
                        <CertificateRecognition
                            settings={settings}
                        />
                    </div>
                    <CertificateHelp/>
                    <div className='text-left mt-6 md:mt-10'>
                        <h3 className='text-2xl md:text-[32px] font-semibold mb-3'>Our Learners Work At</h3>
                        <OurLearnersWorkAt/>
                        
                        <p className='opacity-50 text-sm font-semibold text-center mt-2 underline cursor-pointer' onClick={()=>setIsModalOpen(true)}>View 1000s more</p>
                        <UsersModal
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                        />
                    </div>
                    <div className='text-left mt-6 md:mt-10'>
                        <h3 className='text-2xl md:text-[32px] font-semibold mb-3'>Stand Out From The Crowd</h3>
                        <LinkdInUsers/>
                    </div>
                    <AwardWinning/>
                    <BottomPricingcard
                        handleNavigate={handleNavigate}
                    />
                    <div className='text-left mt-6 md:mt-10'>
                        <h2 className='text-2xl md:text-[32px] font-semibold mb-3'>Frequently Asked Questions</h2>
                        <CertifiedFAQSection
                            sentinelRef={sentinelRef}
                        />
                    </div>
                </div>
            </div> 
            {showCertificateImageModal && <CertificateImageModal />}
            {showCourseModal && <NewCourseDetailsModal />}
            {showCategoryModal && <CategoryModal />}
            {showRelatedSubjectsModal && <RelatedSubjectsModal />}
            {showPlacementModal && <FourWeekPlacementModal />}
        </>
        
    )
}

export default AnalysisPage