import { useState, useRef, useCallback } from "react";
import { Dialog } from "@headlessui/react";
import useModalData from "../../hooks/useModalData";
import UserModal from "./UserModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function UsersModal  ({isOpen, setIsOpen}) {

    const [pageNum, setPageNum] = useState(1)
    const {
        usersData,
        hasNextPage
    } = useModalData(pageNum)

    const intObserver = useRef()
    const lastItemRef = useCallback(item => {
        // if (isLoading) return

        if (intObserver.current) intObserver.current.disconnect()

        intObserver.current = new IntersectionObserver(posts => {
            if (posts[0].isIntersecting && hasNextPage) {
                setPageNum(prev => prev + 1)
            }
        })

        if (item) intObserver.current.observe(item)
    }, [hasNextPage])

    const content = usersData.map((item, i)=>{
        if (usersData.length === i + 1){
            return <UserModal ref={lastItemRef} key={i} item={item}/>
        }
        return <UserModal key={i} item={item}/>
    })
    
    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <div>
            <Dialog
            open={isOpen}
            onClose={handleClose}
            as="div"
            className="fixed inset-0 z-50 flex md:justify-start md:mt-3 justify-start flex-col items-center"
            >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-70 z-10" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className="bg-secondary_background my-auto z-50 w-[97%] h-[31rem] md:w-[480px] rounded-xl flex flex-col items-center p-2 relative">
                    <h2 className="flex-1 text-2xl font-bold pt-2 pb-4 text-center text-white">Our Learners Work At</h2>
                    <div className="overflow-y-scroll takeaway-two pr-2">
                        <FontAwesomeIcon icon={faXmark} className="text-3xl text-white cursor-pointer absolute top-3 right-3" onClick={handleClose} />
                        <div className="grid grid-cols-1 gap-2">{content}</div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

// scrollable-div mt-6 bg-white overflow-y-scroll z-10 w-[28rem] h-[30.2rem] rounded-2xl flex flex-wrap py-4 px-4 pr-4 md:p-4 text-center shadow-2xl md:w-[900px] md:h-[415px]