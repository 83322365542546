import React from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { ClipLoader } from 'react-spinners'
import { useSelector } from 'react-redux'
// import { selectMCQDetails } from '../../redux/mcqSlice'
import { selectSelectedItems } from '../../redux/searchSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import useWindowSize from '../../hooks/useWindowSize'
import { selectAddOns } from '../../redux/addOnSlice'

const BottomStickyBar = ({ isAtEnd, minutes, seconds, price, featurePrice, normalFeaturePrice, handleNavigate }) => {

    // const quizDetails = useSelector(selectMCQDetails)
    const selectedSkills = useSelector(selectSelectedItems)
    const addOns = useSelector(selectAddOns)

    const { threeCertificates, fiveCertificates, sevenCertificates, proMembershipSelected, personalisedSelected, fourWeekPlacementFeature } = addOns
    const customBundleLength = selectedSkills?.length
    // const score = quizDetails?.quiz_score
    const customBundleSelected = customBundleLength > 0

    const { showLoader } = useAddonContext()

    const addOnSelected = threeCertificates || proMembershipSelected || personalisedSelected || fiveCertificates || sevenCertificates || fourWeekPlacementFeature || customBundleSelected

    const { width } = useWindowSize()


    return (
        <div className={`fixed flex justify-between items-center bottom-0 w-full left-0 z-50 md:left-1/2 md:-translate-x-[50%] gap-2 py-3 px-4 md:px-10 ${isAtEnd ? 'hidden' : 'bg-[#26588B]'}`} style={{
            boxShadow: '0 -4px 10px 2px rgba(0, 0, 0, 0.25)'
        }}>
            <div className='flex-[0.4] flex flex-col items-start text-left'>
                <p className='text-xs font-medium mb-1'>{minutes ===0 && seconds === 0 ? 'Time Up. Unlock Now!' : 'Certificate Saved For:'}</p>
                <div className='border-2 border-danger text-danger flex rounded justify-center py-1 px-1.5'>
                    <p className='text-[11px] font-extrabold'>{minutes.toString().padStart(2, '0')}M :</p>
                    <p className='text-[11px] font-extrabold ml-1'>{seconds.toString().padStart(2, '0')}S</p>
                </div>
            </div>
            <div className='max-md:flex-1 flex flex-col items-center'>
                <div className={`bg-secondary-text w-full md:w-fit self-end py-3 px-2 md:px-6 ${width <= 320 ? 'text-sm' : 'text-base'} font-medium rounded-xl text-black cursor-pointer btn--glow`} style={{
                    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)'
                }} onClick={handleNavigate}>
                    {showLoader ? <ClipLoader color='black' size={16} /> : 'Get Instant Certificate'}
                    {!showLoader && <FontAwesomeIcon icon={faArrowRight} className='text-base ml-2 arrow-move'/>}
                </div>
                {/* {score < 40 ? <p className='text-xxs font-semibold mt-1'>
                    Course Included, All For Just: <span className='text-[#80C242]'>&#8377;{price?.price + featurePrice()}</span>
                </p> :  */}
                <p className='text-xxs font-semibold mt-1'>Only <span className='text-[#80C242]'>&#8377;{ price?.price + featurePrice()}</span> <span className='text-danger font-semibold text-[10px]'>&#8377;<span className='line-through'>{`${price?.original_price + normalFeaturePrice()}`}</span></span> {addOnSelected && '(Including Addons)'}</p>
                {/* } */}
            </div>
        </div>
    )
}

export default BottomStickyBar