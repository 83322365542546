import React from 'react'
import { PropagateLoader } from 'react-spinners'

const NewLoader = () => {
    return (
        <div className='w-full h-[100dvh] flex justify-center items-center' style={{
            background: 'radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%)'
        }}>
            <PropagateLoader color='#7FC241' size={20}/>
        </div>
    )
}

export default NewLoader