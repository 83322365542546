import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { axiosAuth, axiosNoAuth } from "../config/axiosInstances"
import { API_XANO_CERTIFIED_BASE_URL } from "../config/api_config"
import { Api_url } from "../common/api_url"

export const fetchSuggestedCourse = createAsyncThunk(
    'fetchCourseSuggestedCourse/postPaymentSlice',
    async ({ certified_user_skill_quiz_id, suggested_bundle_type }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.SuggestedCourseBundle, {
                params: {
                    certified_user_skill_quiz_id,
                    suggested_bundle_type
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchCourseSyllabus = createAsyncThunk(
    'fetchCourseSyllabus',
    async ({ certified_bundle_course_id, type, subject, goal }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.CourseSyllabus,
            { 
                params: { certified_bundle_course_id, Type: type, subject, goal }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }

    }
)

export const fetchToken = createAsyncThunk(
    'fetchToken/postPaymentSlice',
    async ({ id }) => {
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/Create_saved_mcq_url', {
                id
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchPaidFeaturesDetails = createAsyncThunk(
    'fetchPaidFeaturesDetails/postPaymentSlice',
    async ({ token }) => {
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/fetch_post_payment_state', {
                params: {
                    token
                }
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const updateCourseStatus = createAsyncThunk(
    'updateCourseDetails/postPaymentSlice',
    async ({ type, subject, user_id, current_id }) =>{
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/check_if_pre_paid', {
                type,
                subject,
                user_id,
                current_id
            })
            return response.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const userCertifiedOrPersonalisedBundle = createAsyncThunk(
    'userCertifiedOrPersonalisedBundle',
    async ({ course_list, bundle_type, certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.CreateBundle, {
                course_list, bundle_type, certified_user_skill_id
            })
            return { bundle: response.data, type: bundle_type }
        } catch (error) {
            console.log(error)
        }
    }
)

export const removeFromBundle = createAsyncThunk(
    'removeFromBundle',
    async ({ certified_bundle_id, certified_bundle_course_id, type, scholarship_type }) => {
        const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.RemoveFromBundle, { 
            certified_bundle_id, certified_bundle_course_id, bundle_type: type, scholarship_type
        })
        return { bundle: response.data?.data, type } 
    }
)

export const addToBundle = createAsyncThunk(
    'addToBundle',
    async ({ course_name_list, certified_bundle_id, type }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.AddToBundle, {
                course_name_list, certified_bundle_id, bundle_type: type
            })
            if (response.status === 200 && Array.isArray(response.data?.data)){
                return { bundle: response.data?.data, type }
            }
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const getDashBoardData = createAsyncThunk(
    'getDashboardData',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetDashBoardData, {
                params: {
                    certified_user_skill_id
                }
            })
            if (response.status === 200){
                return response.data?.data
            }
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const claimThisCourse = createAsyncThunk(
    'claimThisCourse',
    async ({ certified_bundle_course_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.ClaimCurrentCourse, {
                certified_bundle_course_id
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const getWhatsAppToken = createAsyncThunk(
    'getWhatsAppToken',
    async ({ certified_user_skill_id }) => {
        const maxTries = 3
        let tries = 0
        while (tries < maxTries) {
            try {
                const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetWhatsAppToken, {
                    params: {
                        certified_user_skill_id
                    }
                })
                return response.data?.data
            } catch (error) {
                tries+=1
                console.log(error.message)
            }
        }
    }
)

export const regenerateSuggestedBundle = createAsyncThunk(
    'regenerateSuggestedBundle',
    async ({ certified_user_skill_quiz_id, suggested_bundle_type, feedback }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.RegenerateBundle, {
                params: {
                    certified_user_skill_quiz_id, suggested_bundle_type, feedback
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const replaceOrRemoveCourse = createAsyncThunk(
    'replaceOrRemoveCourse',
    async ({ certified_bundle_id, certified_bundle_course_id, feedback, undo }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.RemoveOrReplace, {
                certified_bundle_id, certified_bundle_course_id, feedback, undo
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const fetchCartData = createAsyncThunk(
    'fetchCartData',
    async ({ certified_user_skill_id, contents }) => {
        try {
            const response = await axiosNoAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.UserCartData, {
                certified_user_skill_id, contents
            })
            if (response.data?.result === 'success'){
                return response.data?.data
            }
            throw new Error('Something went wrong')
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const updateUserCartData = createAsyncThunk(
    'updateUserCartData',
    async ({ certified_user_skill_id, contents }) => {
        try {
            const response = await axiosNoAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.UpdateUserCartData, {
                certified_user_skill_id, contents
            })
            if (response.data?.result === 'success'){
                return response.data?.data
            }
            throw new Error('Something went wrong')
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const getFirstTransactionDetailsForRedeeming = createAsyncThunk(
    'getFirstTransactionDetails',
    async ({ certified_user_skill_id }) => {
        try {
            const response = axiosNoAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetFirstTransactionDetails, {
                params: {
                    certified_user_skill_id
                }
            })
            return (await response).data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const getNewSuggestedBundle = createAsyncThunk(
    'getNewSuggestedBundle',
    async ({ certified_user_skill_quiz_id, suggested_bundle_type }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.NewSuggestedBundle, {
                params: {
                    certified_user_skill_quiz_id,
                    suggested_bundle_type
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const removeFromCertificateBundle = createAsyncThunk(
    'removeFromCertificateBundle',
    async ({ certified_bundle_id, certified_bundle_course_id, reasons }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.RemoveFromCertificateBundle, {
                certified_bundle_id, certified_bundle_course_id, reasons
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const addToCertificateBundle = createAsyncThunk(
    'addToCertificateBundle',
    async ({ certified_bundle_id, subject_names, certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.AddToCertificateBundle, {
                certified_bundle_id, subject_names, certified_user_skill_id
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const removeAddOnsFromList = createAsyncThunk(
    'removeAddOnsFromList',
    async ({ certified_bundle_id, addon_ids, reasons, tertiary }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.RemoveAddOnFromBundle, {
                certified_bundle_id, addon_ids, reasons, tertiary
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const addAddOnsToList = createAsyncThunk(
    'addAddOnsToList',
    async ({ certified_bundle_id, addon_ids, tertiary }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.AddAddOnFromBundle, {
                certified_bundle_id, addon_ids, tertiary
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const getNewAddOnList = createAsyncThunk(
    'getNewAddOnList',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosNoAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetAllAddOnsList, {
                params: {
                    certified_user_skill_id
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

const initialState = {
    courseList: {
        suggested_data: [],
        certificate_bundle: {
            certificate_list: []
        },
        addons: []
    },
    deletedList: [],
    courseDetail: {},
    personaliseCourseList: {
        custom_personalized_bundle: []
    },
    certificateBundleList: {
        custom_certified_bundle: []
    },
    discountBasedCertificateBundle: [],
    discountBasedPersonaliseBundle: {
        custom_discounted_certified_personalized: []
    },
    loading: false,
    waToken: '',
    user_id: 0,
    listModalOpened: false,
    paidAddonDetails: {
        addon_details: [],
        bundle_details: []   
    },
    currentCourseDetail: {},
    permanentList: {
        suggested_data: []
    },
    showUserGoalChangeModal: false,
    showUserGoalChangingInput: false,
    showDraftSuggestedBundle: true,
    showWhyDoesThisWork: false,
    newCertificateList: [],
    removeFromCertificateList: [],
    newRemoveFromBundle: [],
    newCourseList: [],
    showCourseModal: false,
    modalCourseDetails: {
        modalCourseName: '',
        modalCourseNoOfStudent: 0,
        modalCourseRating: 5,
        modalCourseIndex: 0,
    },
    showBundleCart: false,
    previousScreen: [],
    showSelectedBundleEditModal: false,
    addOnSelectedForPurchase: [],
    showPlacementModal: false,
    showMentorShipModal: false,
    showProMembershipModal: false,
    toastDetails: {
        tag: '',
        courseId: 0,
        feedback:''
    },
    showToastAtTop: false,
    showToastInsideModal: false,
    showMenu: false,
    scholarshipApplied: false,
    showCartAddingModal: false,
    showCartRemoveModal: false,
    removedFromBundle: [],
    removedCertificateBundle: [],
    calculate: false,
    showPaymentCancelModal: false,
    newBundleId: 0,
    showFromRegeneratePath: false,
    regenerateCount: 0,
    seatsLeft: 0,
    utmSource: '',
    showCertificateImageModal: false,
    modalCertificateImage: '',
    showBanner: false,
    anotherScholarshipApplied: false,
    showWithOutDiscountPaymentCancelModal: false,
    discountPaymentModalOpened: false,
    showDiscountEndedModal: false,
    openOnceDiscountedModal: false,
    visitedCartPage: false,
    showRedeemButton: [],
    userCartData: [],
    discountType: '',
    firstTransactionDetails: {},
    showRedeemPageCertificateModal: false,
    skillRelatedExpertsData: [],
    removedCourseList: [],
    removedCertificateList: [],
    certificateToastDetails: {
        tag: '',
        courseId: 0,
        feedback:''
    },
    removedAddOns: [],
    removedCustomAddOn: {},
    newFullAddOnList: []
}

const postPaymentSlice = createSlice({
    name: 'postPaymentSlice',
    initialState,
    reducers: {
        removeCourse: (state, action) => {
            const newCourseList = state.courseList?.filter(item => item !== action.payload)
            state.courseList = newCourseList
        },
        addToCourseList: (state, action) => {
            state.courseList = {
                ...state.courseList,
                suggested_bundle: action.payload
            }
        },
        setChangedUserGoalCourseList: (state, action) => {
            state.courseList = action.payload
        },
        addToPersonaliseCourseList: (state, action) => {
            state.personaliseCourseList = action.payload
        },
        addToCertificatesList: (state, action) => {
            state.certificateBundleList = action.payload
        },
        addTodeletedList: (state, action) => {
            state.deletedList = [...state.deletedList, action.payload]
        },
        removeFromDeletedList: (state, action) => {
            state.deletedList = state.deletedList.filter(item => item !== action.payload)
        },
        saveToken: (state, action) => {
            state.waToken = action.payload
        },
        setUserIdInPostPayment: (state, action) => {
            state.user_id = action.payload
        },
        setToken: (state, action) => {
            state.waToken = action.payload
        },
        setListModal: (state, action) => {
            state.listModalOpened = action.payload
        },
        setCurrentCourseDetail: (state, action) => {
            state.currentCourseDetail = action.payload
        },
        setPermanentSuggestedBundle: (state, action) => {
            state.permanentList = action.payload
        },
        addItemsToPermanentList: (state, action) => {
            const newList = state.permanentList.suggested_data?.filter(item => !action.payload?.some(course => course.course_name === item.course_name))
            state.permanentList.suggested_data = [...newList, ...action.payload]
        },
        setShowGoalChangeModal: (state, action) => {
            state.showUserGoalChangeModal = action.payload
        },
        setShowUserGoalChangingInput: (state, action) => {
            state.showUserGoalChangingInput = action.payload  
        },
        setShowDraftSuggestedBundle: (state, action) => {
            state.showDraftSuggestedBundle = action.payload
        },
        setUserNewCertificateList: (state, action) => {
            state.newCertificateList = action.payload
        },
        addToNewCertificateList: (state, action) => {
            if (!state.newCertificateList.some((item) => item === action.payload)) {
                state.newCertificateList = [...state.newCertificateList, action.payload]
            }else{
                state.newCertificateList = state.newCertificateList.filter((item) => item !== action.payload)
            }
        },
        setNewRemoveFromBundle: (state, action) => {
            state.newRemoveFromBundle = action.payload
        },
        addToRemoveCourseList: (state, action) => {
            if (!state.newRemoveFromBundle.includes(action.payload)) {
                state.newRemoveFromBundle = [...state.newRemoveFromBundle, action.payload]
            }
        },
        setUserRemoveFromCertificateList: (state, action) => {
            state.removeFromCertificateList = action.payload
        },
        setNewUserCourseList: (state, action) => {
            state.newCourseList = action.payload
        },
        addToNewCourseList: (state, action) => {
            if (!state.newCourseList.includes(action.payload)){
                state.newCourseList = [...state.newCourseList, action.payload]
            }else{
                state.newCourseList = state.newCourseList.filter((item) => item !== action.payload)
            }
        },
        setShowCourseModal: (state, action) => {
            state.showCourseModal = action.payload
        },
        setModalCourseDetails: (state, action) => {
            state.modalCourseDetails = action.payload
        },
        setShowWhyDoesThisWork: (state, action) => {
            state.showWhyDoesThisWork = action.payload
        },
        setShowBundleCart: (state, action) => {
            state.showBundleCart = action.payload
        },
        setPreviousScreen: (state, action) => {
            state.previousScreen = action.payload
        },
        setShowSelectedBundleEditModal: (state, action) => {
            state.showSelectedBundleEditModal = action.payload
        },
        setAddOnSelectedForPurchase: (state, action) => {
            state.addOnSelectedForPurchase = action.payload
        },
        setRegenerateCount: (state) => {
            state.regenerateCount += 1
        },
        addToAddOnSelectedForPurchase: (state, action) => {
            if (state.addOnSelectedForPurchase.some((item) => item?.product_slug === action.payload)){
                state.addOnSelectedForPurchase = state.addOnSelectedForPurchase.filter((item) => item?.product_slug !== action.payload)
            }else{
                state.addOnSelectedForPurchase = [...state.addOnSelectedForPurchase, {
                    product_slug: action.payload
                }]
            }
        },
        setShowPlacementModal: (state, action) => {
            state.showPlacementModal = action.payload
        },
        setShowMentorShipModal: (state, action) => {
            state.showMentorShipModal = action.payload
        },
        setShowProMembershipModal: (state, action) => {
            state.showProMembershipModal = action.payload
        },
        setToastDetails: (state, action) => {
            state.toastDetails = action.payload
        },
        setCertificateToastDetails: (state, action) => {
            state.certificateToastDetails = action.payload
        },
        setShowToastAtTop: (state, action) => {
            state.showToastAtTop = action.payload
        },
        setShowToastInsideModal: (state, action) => {
            state.showToastInsideModal = action.payload
        },
        setShowMenu: (state, action) => {
            state.showMenu = action.payload
        },
        setScholarshipApplied: (state, action) => {
            state.scholarshipApplied = action.payload
        },
        setShowCartAddingModal: (state, action) => {
            state.showCartAddingModal = action.payload
        },
        setShowCartRemoveModal: (state, action) => {
            state.showCartRemoveModal = action.payload
        },
        setCalculate: (state, action) => {
            state.calculate = action.payload
        },
        setShowPaymentCancelModal: (state, action) => {
            state.showPaymentCancelModal = action.payload
        },
        addToRemovedFromBundle: (state, action) => {
            if (!state.removedFromBundle.includes(action.payload)) {
                state.removedFromBundle = [...state.removedFromBundle, action.payload]
            }else{
                state.removedFromBundle = state.removedFromBundle.filter((item) => item !== action.payload)
            }
        },
        addToRemovedFromCertificateBundle: (state, action) => {
            if (!state.removedCertificateBundle.includes(action.payload)) {
                state.removedCertificateBundle = [...state.removedCertificateBundle, action.payload]
            }else{
                state.removedCertificateBundle = state.removedCertificateBundle.filter((item) => item !== action.payload)
            }
        },
        setNewBundleId: (state, action) => {
            state.newBundleId = action.payload
        },
        setShowFromRegeneratePath: (state, action) => {
            state.showFromRegeneratePath = action.payload
        },
        setSeatsLeft: (state, action) => {
            state.seatsLeft = action.payload
        },
        setPostTranUtmSource: (state, action) => {
            state.utmSource = action.payload
        },
        setShowCertificateImageModal: (state, action) => {
            state.showCertificateImageModal = action.payload
        },
        setModalCertificateImage: (state, action) => {
            state.modalCertificateImage = action.payload
        },
        setShowBanner: (state, action) => {
            state.showBanner = action.payload
        },
        setAnotherScholarshipApplied: (state, action) => {
            state.anotherScholarshipApplied = action.payload
        },
        setDiscountPaymentModalOpened: (state, action) => {
            state.discountPaymentModalOpened = action.payload
        },
        setShowWithOutDiscountPaymentCancelModal: (state, action) => {
            state.showWithOutDiscountPaymentCancelModal = action.payload
        },
        setShowDiscountEndedModal: (state, action) => {
            state.showDiscountEndedModal = action.payload
        },
        setOpenOnceDiscountedModal: (state, action) => {
            state.openOnceDiscountedModal = action.payload
        },
        setVisitedCartPage: (state) => {
            state.visitedCartPage = true
        },
        setShowRedeemButton: (state, action) => {
            state.showRedeemButton = action.payload
        },
        updateShowRedeemButton: (state, action) => {
            state.showRedeemButton[action.payload] = true
        },
        setDiscountType: (state, action) => {
            state.discountType = action.payload
        },
        setShowRedeemPageCertificateModal: (state, action) => {
            state.showRedeemPageCertificateModal = action.payload
        },
        setSkillRelatedExpertsData: (state, action) => {
            state.skillRelatedExpertsData = action.payload
        },
        setRemovedCourseList: (state, action) => {
            state.removedCourseList = action.payload
        },
        setRemovedCertificateList: (state, action) => {
            state.removedCertificateList = action.payload
        },
        addToCourseListFromRemovedCourseList: (state, action) => {
            if(!state.removedCourseList){
                state.removedCourseList = []
            }
            state.removedCourseList = [...state.removedCourseList, action.payload]
        },
        addToCertificateListFromRemovedCertificateList: (state, action) => {
            if(!state.removedCertificateList){
                state.removedCertificateList = []
            }
            state.removedCertificateList = [...state.removedCertificateList, action.payload]
        },
        setRemovedAddOnsArray: (state, action) => {
            state.removedAddOns = action.payload
        },
        setRemovedAddOns: (state, action) => {
            if (state.removedAddOns?.some((item) => item?.product_id === action.payload?.product_id)){
                state.removedAddOns = state.removedAddOns?.filter((item) => item?.product_id !== action.payload?.product_id)
            }else{
                state.removedAddOns = [...state.removedAddOns, action.payload]
            }
        },
        setRemovedCustomAddOn: (state, action) => {
            state.removedCustomAddOn = action.payload
        },
        removeFromCourseList: (state, action) => {
            state.removedCourseList = state.removedCourseList.filter((item) => item?.id !== action.payload)
        },
        removeFromCertificateList: (state, action) => {
            state.removedCertificateList = state.removedCertificateList.filter((item) => item?.id !== action.payload)
        },
        resetPrepaymentDetails: (state) => {
            state.courseList = []
            state.deletedList = []
            state.courseDetail = {}
            state.personaliseCourseList = []
            state.certificateBundleList = []
            state.permanentList = []
        },
        resetPersonalBundles: (state) => {
            state.personaliseCourseList = []
            state.certificateBundleList = []
        },
        resetPaymentDetails: (state) => {
            state.paidFeatures = {}
        },
        resetPaidAddonDetails: (state) => {
            state.paidAddonDetails = {
                addon_details: [],
                bundle_details: []   
            }
        },
        resetPostPaymentSlice: (state) => ({
            ...initialState,
            currentCourseDetail: state.currentCourseDetail,
            waToken: state.waToken
        })
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSuggestedCourse.pending, (state) => {
            state.loading = true
        }) 
        builder.addCase(fetchSuggestedCourse.fulfilled, (state, action) => {
            state.loading = false
            state.courseList = action?.payload
        })
        builder.addCase(fetchSuggestedCourse.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(fetchCourseSyllabus.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchCourseSyllabus.fulfilled, (state, action) => {
            state.loading = false
            state.courseDetail = action?.payload
        })
        builder.addCase(fetchToken.fulfilled, (state, action) => {
            state.waToken = action?.payload
        })
        builder.addCase(userCertifiedOrPersonalisedBundle.fulfilled, (state, action) => {
            const { bundle, type } = action.payload
            if (type === 'custom_personalized_bundle'){
                state.personaliseCourseList = bundle
            }else if (type === 'custom_certified_bundle'){
                state.certificateBundleList = bundle
            }else if(type === 'custom_discounted_certified_personalized'){
                state.discountBasedPersonaliseBundle = bundle
            }else{
                state.discountBasedCertificateBundle = bundle
            }
        })
        builder.addCase(addToBundle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(addToBundle.fulfilled, (state, action) => {
            const { bundle, type } = action.payload
            if (type === 'custom_certified_bundle'){
                state.certificateBundleList.custom_certified_bundle = bundle
            }else if (type === 'custom_personalized_bundle'){
                state.personaliseCourseList.custom_personalized_bundle = bundle
            }else if (type === 'custom_discounted_certified_personalized'){
                state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized = bundle
            }else{
                if (state.courseList.suggested_data?.length === 0){
                    state.permanentList = {
                        ...state.permanentList,
                        suggested_data: bundle
                    }
                }
                state.courseList.suggested_data = bundle
                if (state.courseList.suggested_data?.length > state.permanentList?.suggested_data?.length){
                        state.permanentList = {
                            ...state.permanentList,
                            suggested_data: state.courseList.suggested_data
                        }
                }
            }
            state.loading = false
        })
        builder.addCase(removeFromBundle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(removeFromBundle.fulfilled, (state, action) => {
            const { bundle, type } = action.payload
            if (type === 'custom_certified_bundle'){
                state.certificateBundleList.custom_certified_bundle = bundle
            }else if (type === 'custom_personalized_bundle'){
                state.personaliseCourseList.custom_personalized_bundle = bundle
            }else if (type === 'custom_discounted_certified_personalized'){
                state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized = bundle
            }else{
                state.courseList.suggested_data = bundle
            }
            state.loading = false
        })
        builder.addCase(removeFromBundle.rejected, (state) => {
            state.certificateBundleList.custom_certified_bundle = [...state.certificateBundleList.custom_certified_bundle]
            state.personaliseCourseList.custom_personalized_bundle = [...state.personaliseCourseList.custom_personalized_bundle]
            state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized = [...state.discountBasedPersonaliseBundle.custom_discounted_certified_personalized]
            state.courseList.suggested_data = [...state.courseList.suggested_data]
        })
        builder.addCase(getDashBoardData.fulfilled, (state, action) => {
            state.paidAddonDetails = action.payload
        })
        builder.addCase(getWhatsAppToken.fulfilled, (state, action) => {
            state.waToken = action.payload
        })
        builder.addCase(regenerateSuggestedBundle.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(regenerateSuggestedBundle.fulfilled, (state, action) => {
            state.loading = false
            state.courseList = action.payload
        })
        builder.addCase(regenerateSuggestedBundle.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(replaceOrRemoveCourse.fulfilled, (state, action) => {
            state.courseList.suggested_data = action.payload
        })
        builder.addCase(fetchCartData.fulfilled, (state, action) => {
            state.userCartData = action.payload
        })
        builder.addCase(updateUserCartData.fulfilled, (state, action) => {
            state.userCartData = action.payload
        })
        builder.addCase(getFirstTransactionDetailsForRedeeming.fulfilled, (state, action) => {
            state.firstTransactionDetails = action.payload
        })
        builder.addCase(getNewSuggestedBundle.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getNewSuggestedBundle.fulfilled, (state, action) => {
            state.loading = false
            state.courseList = action?.payload
        })
        builder.addCase(addToCertificateBundle.fulfilled, (state, action) => {
            state.courseList.certificate_bundle.certificate_list = action.payload
        })
        builder.addCase(removeFromCertificateBundle.fulfilled, (state, action) => {
            state.courseList.certificate_bundle.certificate_list = action.payload
        })
        builder.addCase(removeAddOnsFromList.fulfilled, (state, action) => {
            state.courseList = action.payload
        })
        builder.addCase(addAddOnsToList.fulfilled, (state, action) => {
            state.courseList = action.payload
        })
        builder.addCase(getNewAddOnList.fulfilled, (state, action) => {
            state.newFullAddOnList = action.payload
        })
    }
})

export const { removeCourse, addToCourseList, resetPostPaymentSlice, addTodeletedList, removeFromDeletedList, addToPersonaliseCourseList, addToCertificatesList, saveToken, setUserIdInPostPayment, setToken, setListModal, resetPrepaymentDetails, resetPaymentDetails, resetPersonalBundles, setCurrentCourseDetail, resetPaidAddonDetails, setPermanentSuggestedBundle, setChangedUserGoalCourseList, addItemsToPermanentList, setShowGoalChangeModal, setShowUserGoalChangingInput, setShowDraftSuggestedBundle, addToRemoveCourseList, addToNewCertificateList, setNewRemoveFromBundle, setUserNewCertificateList, setShowCourseModal, setModalCourseDetails, setShowWhyDoesThisWork, setShowBundleCart, setPreviousScreen, setShowSelectedBundleEditModal, addToNewCourseList, setNewUserCourseList, addToAddOnSelectedForPurchase, setAddOnSelectedForPurchase, setShowPlacementModal, setShowMentorShipModal, setShowProMembershipModal, setToastDetails, setShowToastAtTop, setShowToastInsideModal, setShowMenu, setScholarshipApplied, setShowCartAddingModal, setShowCartRemoveModal, setUserRemoveFromCertificateList, addToRemovedFromBundle, addToRemovedFromCertificateBundle, setCalculate, setShowPaymentCancelModal, setNewBundleId, setShowFromRegeneratePath, setRegenerateCount, setSeatsLeft, setPostTranUtmSource, setShowCertificateImageModal, setModalCertificateImage, setShowBanner, setAnotherScholarshipApplied, setShowWithOutDiscountPaymentCancelModal, setDiscountPaymentModalOpened, setShowDiscountEndedModal, setOpenOnceDiscountedModal, setVisitedCartPage, setShowRedeemButton, updateShowRedeemButton, setDiscountType, setShowRedeemPageCertificateModal, setSkillRelatedExpertsData, setRemovedCourseList, removeFromCourseList, addToCourseListFromRemovedCourseList, setRemovedCertificateList, addToCertificateListFromRemovedCertificateList, setCertificateToastDetails, removeFromCertificateList, setRemovedAddOns, setRemovedCustomAddOn: setUserRemovedCustomAddOn, setRemovedAddOnsArray } = postPaymentSlice.actions
export const selectSuggestedCourseList = (state) => state?.postPaymentDetails?.courseList
export const selectSuggestedCourseDetail = (state) => state?.postPaymentDetails?.courseDetail
export const selectLoadingStatus = (state) => state?.postPaymentDetails?.loading
export const selecteDeletedList = (state) => state?.postPaymentDetails?.deletedList
export const selectPersonalizeList = (state) => state?.postPaymentDetails?.personaliseCourseList
export const selectCertificateBundleList = (state) => state?.postPaymentDetails?.certificateBundleList
export const selectWaToken = (state) => state?.postPaymentDetails?.waToken
export const selectListModalStatus = (state) => state?.postPaymentDetails?.listModalOpened
export const selectDiscountBasedCertificateBundle = (state) => state?.postPaymentDetails?.discountBasedCertificateBundle
export const selectDashBoardData = (state) => state?.postPaymentDetails?.paidAddonDetails?.addon_details
export const selectBundlesData = (state) => state?.postPaymentDetails?.paidAddonDetails?.bundle_details
export const selectCurrentClaimingCourse = (state) => state.postPaymentDetails?.currentCourseDetail
export const selectDiscountedPersonalizeBundleList = (state) => state.postPaymentDetails?.discountBasedPersonaliseBundle
export const selectPermanentBundle = (state) => state.postPaymentDetails?.permanentList
export const selectShowGoalChangeModal = (state) => state.postPaymentDetails.showUserGoalChangeModal
export const selectGoalChangingInput = (state) => state.postPaymentDetails.showUserGoalChangingInput
export const selectShowDraftSuggestedBundle = (state) => state.postPaymentDetails.showDraftSuggestedBundle
export const selectNewCertificateList = (state) => state.postPaymentDetails.newCertificateList
export const selectNewRemoveFromBundle = (state) => state.postPaymentDetails.newRemoveFromBundle
export const selectNewCourseList = (state) => state.postPaymentDetails.newCourseList
export const selectShowCourseModal = (state) => state.postPaymentDetails.showCourseModal
export const selectModalCourseDetails = (state) => state.postPaymentDetails.modalCourseDetails
export const selectWhyDoesThisWork = (state) => state.postPaymentDetails.showWhyDoesThisWork
export const selectShowBundleCart = (state) => state.postPaymentDetails.showBundleCart
export const selectPreviousScreen = (state) => state.postPaymentDetails.previousScreen
export const selectShowSelectedBundleEditModal = (state) => state.postPaymentDetails.showSelectedBundleEditModal
export const selectAddOnSelectedForPurchase = (state) => state.postPaymentDetails.addOnSelectedForPurchase
export const selectShowPlacementModal = (state) => state.postPaymentDetails.showPlacementModal
export const selectShowMentorShipModal = (state) => state.postPaymentDetails.showMentorShipModal
export const selectShowProMembershipModal = (state) => state.postPaymentDetails.showProMembershipModal
export const selectToastContent = (state) => state.postPaymentDetails.toastDetails
export const selectCertificateToastContent = (state) => state.postPaymentDetails.certificateToastDetails
export const selectShowToastAtTop = (state) => state.postPaymentDetails.showToastAtTop
export const selectShowToastInsideModal = (state) => state.postPaymentDetails.showToastInsideModal
export const selectScholarshipApplied = (state) => state.postPaymentDetails.scholarshipApplied
export const selectShowMenu = (state) => state.postPaymentDetails.showMenu
export const selectShowCartAddingModal = (state) => state.postPaymentDetails.showCartAddingModal
export const selectshowCartRemoveModal = (state) => state.postPaymentDetails.showCartRemoveModal
export const selectRemoveFromCertificateList = (state) => state.postPaymentDetails.removeFromCertificateList
export const selectRemovedFromBundle = (state) => state.postPaymentDetails.removedFromBundle
export const selectRemovedFromCertificateBundle = (state) => state.postPaymentDetails.removedCertificateBundle
export const selectCalculateValue = (state) => state.postPaymentDetails.calculate
export const selectPaymentCancelModal = (state) => state.postPaymentDetails.showPaymentCancelModal
export const selectNewBundleId = (state) => state.postPaymentDetails.newBundleId
export const selectShowFromRegeneratePath = (state) => state.postPaymentDetails.showFromRegeneratePath
export const selectRegenerateCount = (state) => state.postPaymentDetails.regenerateCount
export const selectSeatLeft = (state) => state.postPaymentDetails.seatsLeft
export const selectPostTranUtmSource = (state) => state.postPaymentDetails.utmSource
export const selectshowCertificateImageModal = (state) => state.postPaymentDetails.showCertificateImageModal
export const selectCertificateImage = (state) => state.postPaymentDetails.modalCertificateImage
export const selectShowBanner = (state) => state.postPaymentDetails.showBanner
export const selectAnotherScholarshipApplied = (state) => state.postPaymentDetails.anotherScholarshipApplied
export const selectDiscountPaymentModalOpened = (state) => state.postPaymentDetails.discountPaymentModalOpened
export const selectShowWithOutDiscountPaymentCancelModal = (state) => state.postPaymentDetails.showWithOutDiscountPaymentCancelModal
export const selectDiscountEndedModal = (state) => state.postPaymentDetails.showDiscountEndedModal
export const selectOpenOnceDiscountedModal = (state) => state.postPaymentDetails.openOnceDiscountedModal
export const selectVisitedCartPage = (state) => state.postPaymentDetails.visitedCartPage
export const selectShowRedeemButton = (state) => state.postPaymentDetails.showRedeemButton
export const selectUserCartData = (state) => state.postPaymentDetails.userCartData
export const selectDiscountType = (state) => state.postPaymentDetails.discountType
export const selectFirstTransactionDetails = (state) => state.postPaymentDetails.firstTransactionDetails
export const selectShowRedeemPageCertificateModal = (state) => state.postPaymentDetails.showRedeemPageCertificateModal
export const selectExpertsData = (state) => state.postPaymentDetails.skillRelatedExpertsData
export const selectRemovedCourseList = (state) => state.postPaymentDetails.removedCourseList
export const selectRemovedCertificateList = (state) => state.postPaymentDetails.removedCertificateList
export const selectRemovedAddOns = (state) => state.postPaymentDetails.removedAddOns
export const selectRemovedCustomAddOn = (state) => state.postPaymentDetails.removedCustomAddOn
export const selectFullNewAddOnList = (state) => state.postPaymentDetails.newFullAddOnList
export default postPaymentSlice.reducer