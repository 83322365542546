import store from './store'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { ENV } from './config/api_config'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import AddonFeatureProvider from './context/AddonFeatureContext'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from '@sentry/browser'
import { ToastContainer } from 'react-toastify'

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store)

Sentry.init({
    dsn: "https://106a10226e9c98ac1d2c082dfcc1dc45@o4505623701159936.ingest.us.sentry.io/4508602322845696",
    integrations: [
        new BrowserTracing(),
        new Sentry.Replay(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/certified\.learntube\.ai/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AddonFeatureProvider>
                <Router>
                    <ToastContainer />
                    <App />
                </Router>
            </AddonFeatureProvider>
        </PersistGate>
    </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (ENV === "PRODUCTION") console.log = function () { };