import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_XANO_CERTIFIED_BASE_URL } from "../config/api_config";
import { Api_url } from "../common/api_url";

export const fetchQuestions = createAsyncThunk(
    'questions/fetch',
    async ({ skillName, skillLevel, utm_source, utm_medium, utm_campaign }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/q_generation', { skill_name:skillName, skill_difficulty:skillLevel, utm_source:utm_source, utm_medium:utm_medium, utm_campaign: utm_campaign });
                if (response.data === true || response.data === false) {
                    const navigate = useNavigate()
                    return navigate('/')
                }
                return response.data;
            } catch (err) {
                console.log(err);
                retries += 1;
            }
    }
        toast.error('Error in fetching questions. Please try again later.');
    })

export const dispatchName = createAsyncThunk(
    'name/dispatch',
    async ({ uid, name}) => {
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/saving_name_for_course', { uid: uid, name: name })
            return response.data
            
        } catch (err) {
            console.log(err)
        }
    }
)

export const dispatchUserdetails = createAsyncThunk(
    'userDetails/fetch',
    async ({ uuid, phoneNumber, email }) => {
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/user_details', { uid: uuid, phone_number: phoneNumber, email: email});
            if (response.data === true || response.data === false) {
                const navigate = useNavigate()
                return navigate('/')
            }
            return response.data;
        }
        catch (err) {
            console.log(err)
        }
});

export const fetchAnalysisforAll = createAsyncThunk(
    'analysis/fetchForAll',
    async ({ question, answer, uid, step }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries) {
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/answer_analysis', { question:question, answer:answer, uid:uid });
            if (response.data === true || response.data === false) {
                const navigate = useNavigate()
                return navigate('/')
            }
            return {step: step, question: question, answer: answer, analysis:response.data};
        } catch (err) {
            console.log(err);
            retries += 1;
        }
    }
    toast.error('Error in fetching analysis. Please try again later.');
    }
);
    
export const fetchAnalysis = createAsyncThunk(
    'analysis/fetch',
    async ({ question, answer, uid, name, step }) => {
        const maxRetries = 3;
        let retries = 0;
        while (retries < maxRetries){
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/answer_analysis', { question:question, answer:answer, uid:uid, name:name });
                if (response.data === true || response.data === false) {
                    const navigate = useNavigate()
                    return navigate('/')
                }
                return {step: step, question: question, answer: answer, analysis:response.data};
            } catch (err) {
                console.log(err);
                retries += 1;
            }
        }
        toast.error('Error in fetching analysis. Please try again later.');
    }
);


export const fetchCourseSyllabus = createAsyncThunk(
    'personaliseCourseSyllabus/fetchCourseSyllabus',
    async ({ subject, goal, wrong_part, weak_area, student_knows, email }) => {
        const maxRetries = 3
        let retries = 0;
        while (retries < maxRetries){
            try {
                const response = await axios.post('https://certified-backend.learntube.ai/mcq_course', {
                    subject: subject, goal: goal, wrong_part: wrong_part, weak_area: weak_area, student_knows: student_knows, email: email
                })
                return response.data

            } catch (e) {
                console.log(e.message);
                retries += 1
            }
        }
    })

export const fetchCourseStatus = createAsyncThunk(
    'beforePayment/fetchCourseStatus',
    async ({ user_name, user_uuid, course_name, skill_difficulty, user_email }) => {      
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/course_before_payment', { user_name: user_name, user_uuid: user_uuid, course_name: course_name, skill_difficulty: skill_difficulty, user_email: user_email });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
)

export const fetchUserCourseFromTypeformId = createAsyncThunk(
    'fetchUserCourseFromTypeform',
    async ({ certified_user_skill_id, utm_source }) => {
        try {
            const response = await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:GaQJaGJH/id_to_course_v2', {
                certified_user_skill_id,
                utm_source
            })
            return response.data?.data
        } catch (error) {
            
        }
    }
)

export const fetchCourses = createAsyncThunk(
    'courses/fetch',
    async ({uuid, skillName, skillLevel, userKnows, userMissed}) => {
        const maxRetries = 2;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/course_generate', {
                    uid:uuid, skill_name: skillName, skill_difficulty: skillLevel, user_knows: userKnows, user_missed: userMissed
                });
                if (response.data === true || response.data === false) {
                    const navigate = useNavigate()
                    navigate('/')
                    return;
                }
                return response.data;
            } catch (err) {
                console.log(err);
                retries += 1;
            }
        }
    }
)

export const fetchCurationStatus = createAsyncThunk(
    'postPayment/fetchCurationStatus',
    async ({ user_name, user_uuid, course_name, skill_difficulty, user_email, is_curation, new_uuid }) => {      
        try {
            const response = await axios.post('https://ninjacurate.learntube.ai/course_after_paid', { user_name: user_name, user_uuid: user_uuid, course_name: course_name, skill_difficulty: skill_difficulty, user_email: user_email, is_curation: is_curation, new_uuid: new_uuid });
            return response.data;
        } catch (err) {
            console.log(err);
        }
  }
);

export const fetchProjects = createAsyncThunk(
    'projects/fetch',
    async ({ uuid, skillName, skillLevel, is_curation, old_uid }) => {
        const maxRetries = 2;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axios.post('https://ninjacurate.learntube.ai/course_extra', {
                    uid:uuid, skill_name: skillName, skill_difficulty: skillLevel, is_curation: is_curation, old_uid: old_uid
                });
                if (response.data === true || response.data === false) {
                    return
                }
                return response.data;

            } catch (err) {
                if(retries === 2){
                    throw err
                }
                console.log(err)
                retries += 1
            }
        }
    })

export const fetchPersonalisedRazorPayStatus = createAsyncThunk(
    "razorPay/fetch",
    async ({ uuid, payment_id, order_id, isError, razorpay_signature, errorObj }) => {
        const response = await axios.post(
            "https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/payment_status",
            {
                uid: uuid,
                payment_id:payment_id, 
                order_id:order_id, 
                isError:isError, 
                razorpay_signature:razorpay_signature, 
                errorObj:errorObj
            }
        );
        return response.data;
    },
)

export const markPersonalisedPaid = createAsyncThunk(
    "markPersonalisedPaid",
    async ({ uuid }) => {
        try {
            const response = await axios.get(API_XANO_CERTIFIED_BASE_URL + Api_url.MarkPersonalisedPaid, {
                params: {
                    uuid
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    courseContent: {},
    beforePayment: {},
    courseExtra: {},
    questionDetails: {},
    analysisDetails: [],
    questionAnswer: [],
    personaliseCourseContent: {},
    loading: false
}

const personaliseCourseSyllabusSlice = createSlice({
    name: 'personaliseCourseSyllabus',
    initialState,
    reducers: {
        addQuestionAnswer: (state, action) => { 
            state.questionAnswer =  [...state.questionAnswer, action.payload]
        },
        addUserCourse: (state, action) => {
            state.courseContent = action.payload
        },
        resetCourseContent: (state) => initialState,
        resetPreviousCourseContent: (state) => {
            state.courseContent = {}
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCourseSyllabus.fulfilled, (state, action) => {
            state.courseContent = action.payload
        })
        builder.addCase(fetchUserCourseFromTypeformId.fulfilled, (state, action) => {
            state.courseContent = action.payload
        })
        builder.addCase(fetchCourseStatus.fulfilled, (state, action) => {
            state.beforePayment = action.payload
        })
        builder.addCase(fetchProjects.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.loading = false
            state.courseExtra = action.payload
        })
        builder.addCase(fetchProjects.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(fetchQuestions.fulfilled, (state, action) => {
            state.questionDetails = action.payload
        })
        builder.addCase(fetchAnalysis.fulfilled, (state, action) => {
            state.analysisDetails = [...state.analysisDetails, action.payload];
        })
        builder.addCase(fetchAnalysisforAll.fulfilled, (state, action) => {
            state.analysisDetails =  [...state.analysisDetails, action.payload];
        })
        builder.addCase(fetchCourses.fulfilled, (state, action) => {
            state.personaliseCourseContent =  action.payload
        })
    }
})


export const { resetCourseContent, addQuestionAnswer, addUserCourse, resetPreviousCourseContent } = personaliseCourseSyllabusSlice.actions
export const selectCourseSyllabus = (state) => state.personaliseCourseSyllabus.courseContent
export const selectProjectsAndOutcomes = (state) => state.personaliseCourseSyllabus.courseExtra
export const selectQuestionList = (state) => state.personaliseCourseSyllabus.questionDetails.question_list
export const selectUUID = (state) => state.personaliseCourseSyllabus.questionDetails.uuid
export const selectAnalysisDetails = (state) => state.personaliseCourseSyllabus.analysisDetails
export const selectQuestionAnswers = (state) => state.personaliseCourseSyllabus.questionAnswer
export const selectCurationStatus = (state) => state.personaliseCourseSyllabus.beforePayment?.is_curation
export const selectXanoCourseContent = (state) => state.personaliseCourseSyllabus.beforePayment
export const selectCuratedCourseContent = (state) => state.personaliseCourseSyllabus.personaliseCourseContent
export const selectProjectsLoading = (state) => state.personaliseCourseSyllabus.loading
export default personaliseCourseSyllabusSlice.reducer